<template>
  <div>
    <MobileTestDetails v-if="!isLaptop" />
    <LaptopTestDetailsVue v-else />
  </div>
</template>

<script>
import LaptopTestDetailsVue from "../components/TestDetails.vue";
import MobileTestDetails from "../components/mobileView/MobileTestDetails.vue";
export default {
  name: "TestDetails",
  data() {
    return {
      isLaptop: true,
    };
  },
  components: {
    LaptopTestDetailsVue,
    MobileTestDetails,
  },
  created() {
    this.$store.commit("sectionVUEX/saveSelectedSection",{})
    this.$store.commit("sectionVUEX/savetotalanswered",{})
  },
  mounted() {
    this.checkScreenSize(); // Check screen size initially
    window.addEventListener("resize", this.checkScreenSize); // Add event listener for window resize
    console.log("mount");
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.checkScreenSize); // Remove event listener before component unmounts
  },
  methods: {
    checkScreenSize() {
      this.isLaptop = window.innerWidth >= 1024;
    },
  },
};
</script>
