<template>
  <div style="height: 100vh">
    <div class="login-main-container">
      <div class="h-100 d-flex align-items-center justify-content-center">
        <div class="login-main-sub-container p-0 position-relative">
          <b-row class="m-0 w-100">
            <b-col class="p-0">
              <div
                class="w-100 pt-3 d-flex justify-content-center align-items-baseline"
              >
                <img src="../assets/newimgs/GREYLOGO.svg" alt="" />
              </div>
            </b-col>
          </b-row>
          <b-row class="m-0 w-100" style="height: 90%">
            <b-col class="p-0 h-100">
              <div class="testdetails-container h-100 mt-3">
                <h1 class="login-rightcont-headtxt mb-3">Test Details</h1>
                <div
                  style="
                    height: calc(100vh - 295px);
                    overflow-y: auto;
                    overflow-x: hidden;
                    padding-right: 5px;
                  "
                >
                  <b-row class="">
                    <b-col cols="9">
                      <div class="d-flex">
                        <div class="w-100">
                          <div
                            class="d-flex justify-content-between align-items-baseline"
                          >
                            <div v-if="testDetails.jobID" class="">
                              <div class="test-details-headings">
                                <h3>Company</h3>
                              </div>
                              <div class="testdetails-content-txt text-break">
                                {{ testDetails.customerName }}
                              </div>
                            </div>
                            <div v-else class="">
                              <div class="test-details-headings">
                                <h3>Test Organizer</h3>
                              </div>
                              <div class="testdetails-content-txt text-break">
                                {{ testDetails.config.testOrg }}
                              </div>
                            </div>
                          </div>

                          <!-- <div
                            class="mt-4"
                            v-if="testDetails.config.testInst != ''"
                          >
                            <div class="d-flex">
                              <div class="ml-2">
                                <div class="test-details-headings">
                                  <h3>Test Instructions</h3>
                                </div>
                                <div
                                  class="testdetails-content-txt text-break"
                                  v-html-safe="testDetails.config.testInst"
                                ></div>
                              </div>
                            </div>
                          </div> -->
                        </div>
                      </div>
                      <div class="d-flex mt-3" v-if="testDetails.jobID">
                        <div class="w-100">
                          <div 
                            class="d-flex justify-content-between align-items-baseline"
                          >
                            <div class="">
                              <div class="test-details-headings">
                                <h3>Job Title</h3>
                              </div>
                              <div class="testdetails-content-txt text-break">
                                {{ testDetails.jobName }}
                              </div>
                            </div>
                          </div>

                          <!-- <div
                            class="mt-4"
                            v-if="testDetails.config.testInst != ''"
                          >
                            <div class="d-flex">
                              <div class="ml-2">
                                <div class="test-details-headings">
                                  <h3>Test Instructions</h3>
                                </div>
                                <div
                                  class="testdetails-content-txt text-break"
                                  v-html-safe="testDetails.config.testInst"
                                ></div>
                              </div>
                            </div>
                          </div> -->
                        </div>
                      </div>
                      <hr />
                      <div class="mt-3">
                        <b-row class="w-100">
                          <b-col cols="4">
                            <div class="d-flex">
                              <div class="">
                                <div class="d-flex align-items-baseline">
                                  <div>
                                    <img
                                      src="../assets/newui-img/calendar.svg"
                                      alt=""
                                    />
                                  </div>
                                  <h3 class="test-details-headings ml-1">
                                    Start Date:
                                  </h3>
                                </div>
                                <div class="testdetails-content-txt">
                                  {{ convertToIST(testDetails.config.openTestdt).slice(13,22) }},
                                  {{
                                    formatDate(
                                      testDetails.config.openTestdt,
                                      "dd-MM-yyyy"
                                    )
                                  }}
                                 
                                </div>
                              </div>
                            </div>
                          </b-col>
                          <b-col cols="4">
                            <div class="d-flex">
                              <div class="">
                                <div class="d-flex align-items-baseline">
                                  <div>
                                    <img
                                      src="../assets/newui-img/calendar.svg"
                                      alt=""
                                    />
                                  </div>
                                  <h3 class="test-details-headings ml-1">
                                    End Date:
                                  </h3>
                                </div>
                                <div class="testdetails-content-txt">
                                  {{ convertToIST(testDetails.config.closeTestdt).slice(13,22) }},
                                  {{
                                    formatDate(
                                      testDetails.config.closeTestdt,
                                      "dd-MM-yyyy"
                                    )
                                  }}
                                    
                                </div>
                              </div>
                            </div>
                          </b-col>
                          <b-col cols="4">
                            <div class="d-flex">
                              <div class="mt-2">
                                <h3 class="test-details-headings">
                                  Total Attempts
                                </h3>
                                <div class="testdetails-content-txt">
                                  {{ testDetails.config.maxnoattempts }}
                                </div>
                              </div>
                            </div>
                          </b-col>
                          <b-col
                            cols="4"
                            v-if="
                              testDetails.config.timebnvalue != null &&
                                testDetails.config.maxnoattempts > 1
                            "
                          >
                            <div class="d-flex mt-3">
                              <div class="">
                                <h3 class="test-details-headings">
                                  Time Between Attempts
                                </h3>
                                <div class="testdetails-content-txt">
                                  {{ testDetails.config.timebnvalue }}
                                  {{
                                    testDetails.config.timebnsel == "mins"
                                      ? "Minutes"
                                      : testDetails.config.timebnsel == "hrs"
                                      ? "Hours"
                                      : "Days"
                                  }}
                                </div>
                              </div>
                            </div>
                          </b-col>
                          <b-col
                            :cols="
                              testDetails.config.timebnvalue != null &&
                              testDetails.config.maxnoattempts > 1
                                ? '4'
                                : '8'
                            "
                            v-if="testDetails.config.negativeMarkValue != 0"
                          >
                            <div class="d-flex mt-3">
                              <div class="">
                                <h3 class="test-details-headings">
                                  Negative Marks
                                </h3>
                                <div class="testdetails-content-txt">
                                  {{ testDetails.config.negativeMarkValue }}
                                </div>
                              </div>
                            </div>
                          </b-col>
                          <b-col cols="4">
                            <div class="d-flex mt-3">
                              <div class="">
                                <h3 class="test-details-headings">
                                  Total Marks
                                </h3>
                                <div class="testdetails-content-txt">
                                  {{ testDetails.marks }}
                                </div>
                              </div>
                            </div>
                          </b-col>
                        </b-row>
                      </div>
                      <div class="d-flex mt-3">
                        <div class="w-100">
                          <div
                            class="d-flex justify-content-between align-items-baseline"
                          >
                            <div class="">
                              <div class="test-details-headings">
                                <h3>Test Name</h3>
                              </div>
                              <div class="testdetails-content-txt text-break">
                                {{ testDetails.name }}
                              </div>
                              <div
                                class="testdetails-content-txt text-break mt-2"
                                v-html-safe="testDetails.config.testDesc"
                              ></div>
                            </div>
                          </div>

                          <div
                            class="mt-4"
                            v-if="testDetails.config.testInst != ''"
                          >
                            <div class="d-flex">
                              <div class="">
                                <div class="test-details-headings">
                                  <h3>Test Instructions</h3>
                                </div>
                                <div
                                  class="testdetails-content-txt text-break"
                                  v-html-safe="testDetails.config.testInst"
                                ></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-if="testDetails.jobID" class="d-flex mt-3">
                        <div class="w-100">
                          <div class="">
                            <div class="test-details-headings">
                              <h3>Test Organizer</h3>
                            </div>
                            <div class="testdetails-content-txt text-break">
                              {{ testDetails.config.testOrg }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="d-flex mt-3" v-if="testDetails.jobID">
                        <div class="w-100">
                          <div class="">
                            <div class="test-details-headings">
                              <h3>Conducted By</h3>
                            </div>
                            <div class="testdetails-content-txt text-break">
                              Hire3x
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div
                        class="tdnew-cnt-container d-flex align-items-center"
                          v-if="testDetails.config.communicationTest === true || testDetails.config.typingTest === true"
                      >
                        <div class="d-flex" style="width: 48%;" v-if="testDetails.config.communicationTest === true">
                          <div>
                            <img src="../assets/newui-img/cnttset.svg" alt="" />
                          </div>
                          <div class="ml-2">
                            <div class="w-100">
                              <div class="">
                                <div class="testdetails-content-txt text-break">
                                  Communication Test
                                </div>
                                <div class="test-details-cnt-headings mt-1">
                                  <h3>
                                    Describe the picture in your own words using
                                    <br />
                                    keywords given.
                                  </h3>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr
                        v-if="testDetails.config.communicationTest === true && testDetails.config.typingTest === true"
                          class="my-0"
                          style="transform: rotate(90deg);width: 50px;"
                        />
                        <div class="d-flex" style="width: 48%;" v-if="testDetails.config.typingTest === true">
                          <div>
                            <img src="../assets/newui-img/cnttset.svg" alt="" />
                          </div>
                          <div class="ml-2">
                            <div class="w-100">
                              <div class="">
                                <div class="testdetails-content-txt text-break">
                                  Typing Test
                                </div>
                                <div class="test-details-cnt-headings mt-1">
                                  <h3>
                                    Take a typing test to measure your accuracy
                                    by <br />
                                    typing the displayed sentence accurately.
                                  </h3>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-col>
                    <b-col cols="3" style="margin-top: 2rem;">
                      <div
                        class="d-flex"
                        :class="
                          testDetails.config.allowAgent == true ||
                          testDetails.config.allowExtension == true ||
                          testDetails.config.enableProctoring == true
                            ? ''
                            : 'justify-content-center'
                        "
                      >
                        <div
                          class="w-50 testdetails-rightcards d-flex flex-column justify-content-center align-items-center p-3"
                        >
                          <div>
                            <img
                              src="../assets/newimgs/testscreen/duration.svg"
                              alt=""
                            />
                          </div>
                          <div class="d-flex flex-column align-items-center">
                            <div class="testdetails-simpledetails-headtxt">
                              Duration
                            </div>
                            <div class="testdetails-simpledetails-subtxt">
                              {{ testDetails.config.testDuration }} mins
                            </div>
                          </div>
                        </div>

                        <div
                          class="ml-2 w-50 testdetails-rightcards d-flex flex-column justify-content-center align-items-center p-3"
                          v-if="
                            testDetails.config.allowAgent == true ||
                              testDetails.config.allowExtension == true ||
                              testDetails.config.enableProctoring == true
                          "
                        >
                          <div>
                            <img id="proctimg" :src="procImage()" alt="" />
                          </div>
                          <div class="position-relative">
                            <h3
                              class="testdetails-simpledetails-headtxt cursor-default mb-0 mt-1"
                            >
                              Proctoring
                            </h3>
                            <div
                              class="testdetails-simpledetails-subtxt proc-tooltip-trigger cursor-default pointer"
                            >
                              Enabled
                              <img class=" tooltip-shadow ml-1 mb-1" src="../assets/newui-img/test-screen/i_tooltip.svg"/>
                            </div>
                            <div class="proc-tooltip">
                              {{ procText() }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="mt-3 w-100 testdetails-rightcards d-flex justify-content-between align-items-center p-3"
                      >
                        <div
                          class="d-flex align-items-center justify-content-center flex-column"
                        >
                          <div>
                            <img
                              src="../assets/newimgs/testscreen/totalquestion.svg"
                              alt="" class="w-100 h-100"
                            />
                          </div>
                          <div class="text-center mt-2">
                            <h3 class="testdetails-simpledetails-headtxt">
                              Total Questions
                            </h3>
                            <div class="testdetails-simpledetails-subtxt">
                              {{ testDetails.noOfQuestions }}
                            </div>
                          </div>
                        </div>
                        <div
                          class="d-flex align-items-center justify-content-center flex-column"
                        >
                          <div>
                            <img
                              src="../assets/newimgs/testscreen/totalsection.svg"
                              alt="" class="w-100 h-100"
                            />
                          </div>
                          <div class="text-center mt-2">
                            <h3 class="testdetails-simpledetails-headtxt">
                              Total Sections
                            </h3>
                            <div class="testdetails-simpledetails-subtxt">
                              {{ testDetails.numberOfSections }}
                            </div>
                          </div>
                        </div>
                        <div
                          class="d-flex align-items-center justify-content-center flex-column"
                        >
                          <div>
                            <img
                              src="../assets/newimgs/testscreen/difficultylevel.svg"
                              alt="" class="w-100 h-100"
                            />
                          </div>
                          <div class="text-center mt-2">
                            <h3 class="testdetails-simpledetails-headtxt">
                              Difficulty Level
                            </h3>
                            <div class="testdetails-simpledetails-subtxt">
                              {{ testDetails.difficultyLevel }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </div>
              </div>
              <hr class="mb-0" />
              <div
                class="px-4 py-3 d-flex align-items-center justify-content-between bluebtns"
              >
                <b-button
                  variant="outline-primary"
                  @click="$bvModal.show('leavetestmodal')"
                  >Leave Test</b-button
                >
                <b-button variant="primary"   class="px-4" @click="next()"
                  >Continue</b-button
                >
              </div>
            </b-col>
          </b-row>
        </div>
        <b-modal
        id="leavetestmodal"
        size="md"
        centered
        hide-footer
        hide-header
        no-close-on-backdrop
        :no-close-on-esc="true"
      >
        <div
          class="newmodalheadings mt-3 d-flex align-items-center justify-content-center"
        >
          Alert !
        </div>
        <div
          @click="$bvModal.hide('leavetestmodal')"
          class="modalcloseicon pointer"
        >
          <img
            class="rotate-on-hover"
            src="../assets/newimgs/closeicon.svg"
            alt=""
          />
        </div>
        <div
          class="d-flex flex-column align-items-center justify-content-center mt-4 mb-4"
        >
          <div>
            <img src="../assets/newui-img/leavetest.svg" alt="" />
          </div>
          <div class="newui-modal-header mt-4">
            Are you sure you want to leave the test ?
          </div>
          <div class="newui-modal-bodytxt mt-3">
            Please note this action cannot be undone
          </div>
          <div class="bluebtns mt-5">
            <b-button
              class="mr-3 pl-4 pr-4"
              variant="outline-primary"
              @click="$bvModal.hide('leavetestmodal')"
              >Cancel</b-button
            >
            <b-button variant="primary" class="pl-4 pr-4" @click="leave()"
              >Leave Test</b-button
            >
          </div>
        </div>
      </b-modal>
      </div>
    </div>
    <b-modal id="systemcheckmodal" centered hide-footer hide-header size="lg">
      <div class="syscheck-modal-head mt-2 d-flex align-items-center justify-content-center">
        Enable System Permissions
      </div>

      <div class="mt-4 ml-3">
        <b-row>
          <div class="mt-2 syscheck-modal-subtxt">
             1.&nbsp; Please Click on view site information to allow all permissions
            </div>
        </b-row>
        <b-row class="mt-2">
          <b-col>
            <div class="mt-3">
              <img src="../assets/images/step1.svg" alt="" />
            </div>
          </b-col>
          <b-col>
            <div class="mt-3">
              <img src="../assets/images/step2.svg" alt="" />
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="mt-4 ml-3">
        <b-row>
          <b-col>
            <div class="mt-2 syscheck-modal-subtxt">
             2.&nbsp; After enabling Permissions, Reload the page
            </div>
            <div class="mt-3">
              <img src="../assets/images/step3.svg" alt="" />
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="bluebtns py-2 d-flex align-items-center justify-content-center mt-3">
        <b-button
          variant="primary"
          class="px-5"
          @click="$bvModal.hide('systemcheckmodal')"
          >Okay</b-button
        >
      </div>
    </b-modal>
  </div>
</template>

<script>
import testDetails from "../mixins/testDetails.js";
export default {
  data() {
    return {
      property: "value",
    };
  },
  mixins: [testDetails],
};
</script>

<style>
.proc-tooltip{
  background: #D9D9D9;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
border-radius: 8px;
width: 185px;
height: 48px;
display: none;
position: absolute;

font-family: 'Montserrat',sans-serif;
font-weight: 400;
font-size: 10px;
text-align: center;
transform: translateX(-50%);

color: #696969;
padding: 5px 10px;
  top: 45px;
  left: 30%;
}
.proc-tooltip-trigger:hover + .proc-tooltip {
  display: block;
}

.proc-tooltip::before {
  content: "";
  position: absolute;
  top: -10px; /* Adjust the height of the arrow tip */
  left: 74%;
  margin-left: -5px; /* Half of the arrow's width */
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #D9D9D9 transparent;
}
.tooltip-shadow{
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.25);
  border-radius:50%;
}
.td-tooltip {
  display: none;
  position: absolute;
  background-color: #4c78ce;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 160px;
  text-align: center;
}

.td-tooltip-trigger:hover + .td-tooltip {
  display: block;
}

.td-tooltip::before {
  content: "";
  position: absolute;
  top: -10px; /* Adjust the height of the arrow tip */
  left: 50%;
  margin-left: -5px; /* Half of the arrow's width */
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #4c78ce transparent;
}

.syscheck-modal-head{
font-family: 'Lato',sans-serif;
font-weight: 600;
font-size: 18px;
color: #333333;
}
.syscheck-modal-subtxt{
font-family: 'Lato',sans-serif;
font-weight: 500;
font-size: 16px;
color: #3A3C40;
}
</style>
